export function now() {
    return new Date();
}

export function getAge(birthDate) {
    if (!birthDate) {
        return null;
    }

    const today = now();

    const yearDiff = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        return yearDiff - 1;
    }
    return yearDiff;
}

/**
 * Parses a date string of the format DD/MM/YYYY to a Date
 */
export function parseDate(dateString) {
    if (!dateString) {
        return null;
    }
    return new Date(
        dateString.split('/')
            .map((datePart) => {
                if (datePart.length < 2) {
                    /**
                     * Safari can not handle
                     *     new Date('1900-1-1')
                     * as it results in an "Invalid Date" error
                     * so we prefix to '1900-01-01'
                     */
                    return `0${datePart}`;
                }
                return datePart;
            })
            .reverse()
            .join('-'),
    );
}
