import jQuery from 'jquery';

window.app = window.app || {};

app.glide = (function glideComponent($, undefined) {
    function initialise(){
        /** p.s. Glide is made globally available by the glide import in takeoff/js/libs/index.js */

        new Glide('.glide', {
            type: 'carousel',
            perView: 3,
            gap: 20,
            breakpoints: {
                400: {
                    perView: 1
                },
                800: {
                    perView: 2
                }
            }
        }).mount();
    }

    return {
        'init': initialise
    };
})(jQuery);
