import jQuery from 'jquery';
import Cropper from 'cropperjs';

window.app = window.app || {};

app.image = (function imageComponent($, undefined) {

    const cropWidth = 240;
    const cropHeight = 320;

    function initialize() {
        initLivewireAvailable();
        cropper();
    }

    function initLivewireAvailable() {
        window.livewireAvailable = false;
        if ( document.readyState !== 'loading' ) {
            window.livewireAvailable = (typeof window.Livewire !== "undefined");
        } else {
            document.addEventListener('DOMContentLoaded', function () {
                window.livewireAvailable = (typeof window.Livewire !== "undefined");
            });
        }
    }

    function cropper() {
        const $cropper = $('.js-cropper');
        const $placeholder = $('.js-cropper-placeholder');
        const $canvas = $('.js-cropper-canvas');
        const canvas = $canvas[0];
        let cropper;

        $canvas.width($cropper.width());

        var img = new Image();

        img.onload = function () {
            canvas.height = canvas.width * (img.height / img.width);
            canvas.getContext('2d').drawImage(img, 0, 0, canvas.width, canvas.height);

            cropper = new Cropper(canvas, {
                viewMode: 1,
                dragMode: 'move',
                guides: false,
                center: false,
                aspectRatio: cropWidth / cropHeight,
                background: false,
                cropBoxMovable: false,
                cropBoxResizable: false
            });
        };

        function readFile(input) {
            if (input.files && input.files[0]) {

                var reader = new FileReader();

                reader.onload = function (event) {

                    img.src = event.target.result;

                    $cropper.addClass('has-image');
                    $placeholder.hide();
                };

                reader.readAsDataURL(input.files[0]);
            } else {
                alert('Sorry, you did not select an image.');
            }
        }

        let cropperField = $('.js-cropper-field');
        let cropperSubmit = $('.js-cropper-submit');

        if (window.livewireAvailable) {
            Livewire.on('validation-error', () => {
                cropperField.val('');
            });
        }

        //  Submit button click
        cropperSubmit.on('click', function (e) {
            e.preventDefault();

            const croppedImage = cropper.getCroppedCanvas({
                width: cropWidth,
                height: cropHeight,
            });

            if (croppedImage) {
                const croppedImageData = croppedImage.toDataURL();

                if (window.livewireAvailable) {
                    Livewire.dispatch('photoCropped', {'photoBlob': croppedImageData});
                }
                else {
                    var $form = $(this.form);
                    $form.find('.js-cropper-hidden-field')
                        .val(croppedImageData)
                        .attr('value', croppedImageData);

                    $form.trigger('submit');
                }
            }
            return false;
        });

        $('.js-cropper-rotate').on('click', function (ev) {
            var rotateVal = parseInt($(this).data('deg'), 10);
            cropper.rotate(rotateVal);
        });

        var hasValue = cropperField.val();
        cropperSubmit.prop('disabled', !hasValue);

        cropperField.on('change', function () {
            var hasValue = $(this).val();

            cropperSubmit.prop('disabled', !hasValue);

            if (cropper && cropper.destroy) {
                try {
                    cropper.destroy();
                }
                catch (e) {
                    //sometimes there is no parentNode set on cropper. this throws an unnecessary error
                }
            }

            if (hasValue) {
                readFile(this);
            } else {
                $cropper.removeClass('has-image');
                $placeholder.show();
            }
        });

        $('.js-cropper-delete').on('click', function () {
            $('.js-cropper-field').val('').trigger('change');
        });
    }

    return {
        init: initialize
    };

})(jQuery);
