import jQuery from 'jquery';

window.app = window.app || {};

app.common = (function commonComponent($, undefined) {

    const $document = $(document);
    const $window = $(window);
    const $html = $('html');
    const $body = $('body');
    const $pageHeader = $('.js-page-header');

    function initialize() {

        //  Set initial variables
        this.windowResize();

        $window.on('resize', app.helpers.debounce(this.windowResize, 250, false));

        colorbox();
        toggle();
        testimonial();
        scrollToId();
        accordeon();
        collapsible();
        highlighted();
    }

    function toggle() {
        $('.js-toggle').on('click', function () {
            $($(this).attr('data-target')).fadeToggle();
        });
    }

    function collapsible() {

        function toggleCollapsible($collapsible, isExpanded) {

            $collapsible.toggleClass('is-collapsed', !isExpanded);
            $collapsible.find('.js-collapsible-trigger').attr('aria-expanded', isExpanded);
            $collapsible.find('.collapsible__label').html(function () {
                return isExpanded ? $(this).attr('data-label-expanded') : $(this).attr('data-label-collapsed');
            });
        }

        $('.js-collapsible').each(function () {

            const $this = $(this);

            toggleCollapsible($this, !$this.hasClass('is-collapsed'));
        });

        $('.js-collapsible-trigger').on('click', function (e) {

            e.preventDefault();

            const $this = $(this);
            const $collapsibleParent = $(this).closest('.js-collapsible');
            const $collapsibleLabel = $collapsibleParent.find('.collapsible__label');

            const groupName = $collapsibleParent.attr('data-group');
            const willExpand = $collapsibleParent.hasClass('is-collapsed');

            if (groupName) {
                const $collapsibleSiblings = $(`.js-collapsible[data-group="${groupName}"]`).not($collapsibleParent).addClass('is-collapsed');

                toggleCollapsible($collapsibleSiblings, false);
            }

            toggleCollapsible($collapsibleParent, willExpand);
        });
    }

    function accordeon() {

        $('.js-accordeon-item').on('click', function (e) {

            if (e.target.tagName === 'A') return;

            e.preventDefault();

            var $this = $(this);

            if ($this.hasClass('is-expanded')) return false;

            $this.closest('.js-accordeon').find('.js-accordeon-item').removeClass('is-expanded').find('.js-accordeon-item-content').hide();
            $this.addClass('is-expanded').find('.js-accordeon-item-content').slideToggle();

        }).find('.js-accordeon-item-content').hide();
    }

    function testimonial() {

        var $testimonials = $('.js-testimonial');
        var $testimonialContainer = $('.js-testimonial-container');
        var $testimonialTitle = $testimonialContainer.find('.js-testimonial-title');
        var $testimonialText = $testimonialContainer.find('.js-testimonial-text');
        var $testimonialImage = $testimonialContainer.find('.js-testimonial-image');

        $testimonials.on('click', function () {

            var $this = $(this);
            var testimonialData = $(this).data();

            $testimonialContainer.addClass('is-loading');

            $testimonialTitle.html(testimonialData.title);
            $testimonialText.html(testimonialData.text);
            $testimonialImage.attr('src', testimonialData.image);
            $testimonialContainer.attr('href', testimonialData.video);

            $testimonials.removeClass('is-active');
            $this.addClass('is-active');
        });

        $testimonialImage.on('load', function () {
            $testimonialContainer.removeClass('is-loading');
        });

        $testimonials.first().trigger('click');
    }

    function scrollToId() {

        var $scrollLink = $('.js-scroll-to-id');

        $scrollLink.on('click', function (e) {
            var $target = $($(this).attr('href'));
            e.preventDefault();
            if (!$target.length) return;

            scrollToTarget($target);
        });

        function scrollToTarget(target) {
            var offset = target.offset().top - ($pageHeader.height() + 12);
            $('html, body').animate({
                scrollTop: offset
            }, 500);
        }
    }

    function windowResize() {

        var isFlyoutActive = app.helpers.isBreakpointActive('flyout');

        if (!isFlyoutActive) {
            $body.removeClass('flyout-active');
        }

        $.extend(app.variables, {
            windowWidth: $window.width(),
            windowHeight: $window.height(),
            isFlyoutActive: isFlyoutActive,
        });

        $pageHeader.css({
            position: 'fixed'
        }).next().css({
            marginTop: $pageHeader.height()
        });
    }

    function colorbox() {

        if (typeof $.colorbox == 'undefined') return;

        var defaultOptions = {
            close: '&times;',
            next: '&rsaquo;',
            previous: '&lsaquo;',
            maxWidth: '90%',
            maxHeight: '90%'
        };

        $('.js-gallery-image').colorbox(defaultOptions);

        $('.js-gallery-video').colorbox($.extend({}, defaultOptions, {
            iframe: true,
            innerWidth: 640,
            innerHeight: 480
        }));
    }

    function highlighted() {

        const highlightedFields = [];

        $('[data-highlighted-field]').each(function () {
            const fieldName = $(this).attr('data-highlighted-field');
            if (!highlightedFields.includes(fieldName)) {

                $(`[name="${fieldName}"]`).on('change', function (event) {
                    $(`[data-highlighted-field="${fieldName}"]`).removeClass('is-highlighted')
                        .filter(`[data-highlighted-value="${event.target.value}"]`).addClass('is-highlighted');
                });

                highlightedFields.push(fieldName);
            }
        });
    }

    function finalize() {

        let isJsDone = false;

        function jsDone() {

            if (isJsDone) return;

            $html.addClass('js-done');

            $window.trigger('resize');
            $window.trigger('js_done');

            isJsDone = true;
        }

        $window.on('load', jsDone);

        setTimeout(jsDone, 4000);
    }

    return {
        init: initialize,
        windowResize: windowResize,
        finalize: finalize
    };

})(jQuery);
