import jQuery from 'jquery';
import Cleave from 'cleave.js';
import { getAge, parseDate } from '../utils/date';

window.app = window.app || {};

const CLASS_NO_PARSLEY_FORM_VALIDATION = 'js-no-parsley-form-validation';

app.form = (function formComponent($, undefined) {

    var $document = $(document);
    var $window = $(window);
    var $html = $('html');
    var $body = $('body');
    /**
     * For ALL <form> elements
     * WITHOUT those forms which either
     * - have themselves the class "js-no-parsley-form-validation" (verified in the selector)
     * - or have an ascendant with that class (verified via the filter)
     * so that this functionality can be opted out if needed
     */
    var $forms = $(`form:not(.${CLASS_NO_PARSLEY_FORM_VALIDATION})`)
        .filter(function() {
            return $(this).closest(`.${CLASS_NO_PARSLEY_FORM_VALIDATION}`).length === 0;
        });

    var lang = $html.attr('lang');

    function initialize() {

        app.helpers.extend(window.Parsley.options, {
            classHandler: function (ParsleyField) {
                return ParsleyField.$element.closest('.form__item');
            },
            errorClass: 'has-error',
            errorsContainer: function (ParsleyField) {
                var itemClass = '.form__item';
                var $formItem = ParsleyField.$element.closest(itemClass);
                var $formGroup = $formItem.closest('.form__group, .form__item--radios, .form__item--checkboxes');

                if ($formGroup.length) {
                    return $formGroup;
                } else {
                    return $formItem;
                }
            },
            errorsWrapper: '<ul class="form__errors-list form__errors-client"></ul>',
            excluded: 'input:not(:visible), input.novalidate',
            included: '.should-validate'
        });

        window.Parsley.setLocale(document.documentElement.lang);

        function initDateValidators() {
            if (!$('[data-parsley-min-age], [data-parsley-min-date]').length) return;

            window.Parsley.addValidator('minAge', {
                validateString: function validateString(value, requirement) {
                    const birthDate = parseDate(value);
                    const age = getAge(birthDate);

                    return age >= parseInt(requirement, 10);
                },
                messages: {
                    nl: 'Je moet ouder dan %s zijn om je te registreren als pashouder.',
                    fr: 'Vous devez avoir au minimum %s pour vous enregistrer en tant que détenteur de pass.'
                }
            });

            window.Parsley.addValidator('minDate', {
                validateString: function validateString(value, requirement) {
                    const date = parseDate(value);
                    const minDate = parseDate(requirement);

                    return minDate.getTime() < date.getTime();
                },
                messages: {
                    nl: 'Gelieve een datum na %s in te geven.',
                    fr: 'Veuillez entrer une date après %s.'
                }
            });
        }

        initDateValidators();

        window.Parsley.on('field:error', function () {
            var $errorElement = window.Parsley.options.classHandler(this);
            $errorElement.find('.form__errors-server').remove();
        });

        $forms.on('click', 'button[type=submit]', this.submit);

        cleave();
        dependantFields();
        birthDateFields();
    }

    function birthDateFields() {

        $('[data-min-age]').on('change', function () {

            var $this = $(this);
            var $formItem = $this.closest('.form__item');
            var $message = $this.siblings('.js-min-age-warning');

            var value = $this.val();
            var requirement = $this.attr('data-min-age');

            const birthDate = parseDate(value);
            const age = getAge(birthDate);

            var hasWarning = age < parseInt(requirement, 10);

            $message.toggle(hasWarning);

            if (hasWarning) {
                $formItem.addClass('has-warning');
            } else {
                $formItem.removeClass('has-warning');
            }
        });
    }

    function dependantFields() {

        _initialize();

        $document.on('collectionadd', _initialize);

        function _initialize() {

            $('[data-dependant-field-name]:not(is-initialized)').each(function () {

                const $area = $(this);
                const $form = $area.closest('form');

                const fieldName = $area.attr('data-dependant-field-name');
                const fieldValue = $area.attr('data-dependant-field-value');
                const fieldValues = $area.attr('data-dependant-field-values');

                const values = fieldValue ? [ fieldValue ] : (fieldValues ? fieldValues.split(',') : []);

                const $checkboxFields = $form.find(`input[type="checkbox"][name=${fieldName}]`);
                const $radioFields = $form.find(`input[type="radio"][name=${fieldName}]`);

                $area.find('[required]').attr('data-required', '').prop('required', false);

                $checkboxFields.on('change', function () {

                    let isVisible = false;

                    if ($checkboxFields.length > 1) {

                        $checkboxFields.each(function () {

                            if (this.checked && values.indexOf(this.value) > -1) {

                                isVisible = true;

                                return false;
                            }
                        });

                    } else {

                        const boolValue = !!parseInt(fieldValue, 10);

                        isVisible = (this.checked && boolValue) || (!this.checked && !boolValue);
                    }

                    _toggleArea($area, isVisible);

                }).trigger('change');

                $radioFields.on('change', function () {

                    let isVisible = false;

                    $radioFields.each(function () {

                        if (this.checked && values.indexOf(this.value) > -1) {

                            isVisible = true;

                            return false;
                        }
                    });

                    _toggleArea($area, isVisible);

                }).trigger('change');

                $area.addClass('is-initialized');
            });
        }

        function _toggleArea($area, isVisible) {

            $area.find('[data-required]').prop('required', isVisible);

            if (!isVisible) {
                resetFormElements($area);
            }

            $area.toggle(isVisible);
        }
    }

    function cleave() {
        const $cleaveFields = $('.js-cleave');

        if ($cleaveFields) {
            $cleaveFields.each(function () {
                var $this = $(this);
                var options = {};
                var cleaveType = $this.attr('data-cleave-type');

                if (cleaveType === 'cardnumber') {
                    options.blocks = [2, 7, 2];
                    options.delimiter = '-';
                } else if (cleaveType === 'date') {
                    options.date = true;
                    options.datePattern = ['d', 'm', 'Y'];
                }

                new Cleave(this, options);
            });
        }
    }

    function resetFormElements(scope) {

        if (!scope) return;

        const formElements = $(scope).find('input, select, textarea');

        $.each(formElements, (i, formElement) => {

            if (!formElement.type || $(formElement).is('[disabled], .form__disabled')) return;

            if (formElement.name === '_token' || formElement.name === '_method') return;

            const fieldType = formElement.type.toLowerCase();

            switch (fieldType) {

                case 'text':
                case 'password':
                case 'textarea':
                case 'hidden':

                    formElement.value = '';
                    break;

                case 'radio':
                case 'checkbox':

                    if (formElement.checked) {
                        formElement.checked = false;
                    }

                    break;

                case 'select-one':
                case 'select-multi':

                    formElement.selectedIndex = -1;
                    break;

                default:
                    break;
            }
        });
    }

    function submit() {

        var $form = $(this.form);

        if ($form.data('is-submitted')) {
            return false;
        }

        if ($form.parsley().isValid()) {

            $form.data('is-submitted', true).addClass('is-submitted');

            if ($form.find('.g-recaptcha').length) {

                window.recaptchaSubmit = function () {
                    $form.submit();
                };

                grecaptcha.execute();

                return false;
            }
        }
    }

    return {
        init: initialize,
        submit: submit
    };

})(jQuery);
